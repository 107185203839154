@import url(https://fonts.googleapis.com/css?family=Montserrat&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* apply a natural box layout model to all elements, but allowing components to change */
html {
  box-sizing: border-box;
}
*, *:before, *:after {
  box-sizing: inherit;
}
@font-face {
  font-family: 'Futura PT Book';
  src: url(/static/media/FuturaPTBook.99541f31.otf);
  font-style: normal;
  font-weight: 300;
}
@font-face {
  font-family: 'Futura PT Bold';
  src: url(/static/media/FuturaPTBold.f3483f64.otf);
  font-style: normal;
  font-weight: 600;
}
@font-face {
  font-family: "Duplet";
  src: local("Duplet"), url(/static/media/dupletRegular.d879df4c.woff) format("woff");
}

.App {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: auto;
  align-items: flex-start;
  padding: 10px;
  font-family: 'Duplet';
}

.brightline-font {
  font-family:'Futura PT Book';
  font-style: normal;
  font-size: medium;
  font-weight: 300;
}
.header-font {
  font-family: 'Futura PT Bold';
  font-weight: 600;
  color: #6b6c70;
}

.zippin-font {
  font-family: 'Duplet';
}
.title-font {
  color: #888a8f;
}

.App-logo {
  /* animation: App-logo-spin infinite 20s linear; */
  /* height: 40vmin; */
  pointer-events: none;
}

.App-header {
  background-color: 'none';
  /* min-height: 100vh; */
  display: flex;
  flex-direction: column;
  align-self: center;
  font-size: calc(10px + 2vmin);
  color: #000;
}

.App-link {
  color: #ff5c39;
}

.title {
    font-size: 14px;
  }

  
/* @keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */

